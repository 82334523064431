@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
    --primary-color: #181b3a;
    --secondary-color: #01d293;
    --yokan-blue-color: #2858A5;
    --yokan-blue-light-color: #4779ca;
    --yokan-yellow-primary-color: #FF7E00;
    --yokan-yellow-secondary-color: #ff9401;
    --body-bg: #5a5b91;
    --card-01-bg: linear-gradient(#ef621c, #e1424e);
    --card-02-bg: linear-gradient(#01d293, #56c57a);
    --card-03-bg: #725cff;
    --card-04-bg: #2884ff;
    --heading-color: #fff;
    --small-text-color: #808191;
    --recommend-car01-bg: #e1dfa4;
    --light-white: #e3ecf1;
    --recommend-car03-bg: #f4e3e5;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "DM Sans", sans-serif;
    /* background: var(--body-bg); */
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
}


.main__layout {
    margin-left: 260px;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .main__layout {
        margin-left: 240px;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .main__layout {
        margin-left: 220px;
    }
}


@media only screen and (max-width: 485px) {
    .main__layout {
        margin-left: 200px;
    }
}