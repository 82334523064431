.updateStatus {
    margin-top: 90px;
    padding: 1rem;
    padding-bottom: 50px;
}

.updateStatus-container-header h3 {
    font-size: 35px;
    text-align: center;
}

.update-no-shipment {
    margin: 1rem;
}

.updateStatus-search-div {
    margin: 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.updateStatus-search-div input {
    width: 300px;
    background: #eee;
    border-radius: 10px;
    border: 3px solid #2858A5;
    font-size: 14px;
    padding: 4px 8px;
    margin: 8px 2px;
}

.updateStatus-search-btn {
    font-size: 14px;
    padding: 0.5rem 1.3rem;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    background-color: #ff9401;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.updateStatus-search-btn:hover {
    transform: translateY(-3px);
}

.input-error {
    border-color: red !important;
}

/* ---------------------------------select---------------------------- */

.updateStatus-update-div {
    margin: 1rem;
    display: flex;
    align-items: center;
}

.updateStatus-update-div p {
    padding-right: 1rem;
    font-size: 18px;
    font-weight: bold;
}

.updateStatus-select {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.updateStatus-select select {
    width: 250px;
    padding: 10px 15px;
    margin: 1rem;
    background: #eee;
    border: none;
}

.updateStatus-update-btn {
    margin-right: 2rem;
    font-size: 14px;
    padding: 0.5rem 1.3rem;
    border-radius: 10px;
    background-color: #2858A5;
    color: #fff;
    border: none;
    font-weight: bold;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.updateStatus-update-btn:hover {
    transform: translateY(-3px)
}

.updateStatus-update-btn-cancel {
    margin-right: 2rem;
    font-size: 14px;
    padding: 0.5rem 1.3rem;
    border-radius: 10px;
    background-color: rgb(255, 30, 0);
    color: #fff;
    border: none;
    font-weight: bold;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.updateStatus-update-btn-cancel:hover {
    transform: translateY(-3px)
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.loading-spin {
    background: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .updateStatus-select select {
        width: 250px;
        padding: 10px 15px;
        margin: 1rem 0;
        background: #eee;
        border: none;
    }

    .updateStatus-update-btn {
        margin: 0.5rem 0;
    }

    .updateStatus-update-btn-cancel {
        margin: 0.5rem 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .updateStatus-select select {
        width: 250px;
        padding: 10px 15px;
        margin: 1rem 0;
        background: #eee;
        border: none;
    }

    .updateStatus-update-btn {
        margin: 0.5rem 0;
    }

    .updateStatus-update-btn-cancel {
        margin: 0.5rem 0;
    }

    .updateStatus-container-header h3 {
        font-size: 30px;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .updateStatus-select select {
        width: 300px;
        padding: 10px 15px;
        margin: 8px 2rem;
    }

    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select select {
        width: 350px;
        padding: 10px 15px;
        margin: 1rem 0 1rem 0;
    }

    .updateStatus-select {
        padding: 0;
    }

    .updateStatus-search-div {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
    }

    .updateStatus-search-div input {
        width: 250px;
    }

    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .updateStatus-select select {
        width: 250px;
        padding: 10px 15px;
        margin: 1rem 0;
    }

    .updateStatus-update-btn {
        margin: 0.5rem 0;
    }

    .updateStatus-update-btn-cancel {
        margin: 0.5rem 0;
    }

    .updateStatus-container-header h3 {
        font-size: 27px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .updateStatus-search-div {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
    }

    .updateStatus-search-div input {
        width: 190px;
    }

    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .updateStatus-select select {
        width: 190px;
        padding: 10px 15px;
        margin: 1rem 0;
    }

    .updateStatus-update-btn {
        margin: 0.5rem 0;
    }

    .updateStatus-update-btn-cancel {
        margin: 0.5rem 0;
    }

    .updateStatus-container-header h3 {
        font-size: 22px;
    }
}


@media only screen and (max-width: 485px) {
    .updateStatus-search-div {
        margin: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
    }

    .updateStatus-search-div input {
        width: 150px;
    }

    .updateStatus-update-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .updateStatus-select {
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .updateStatus-select select {
        width: 150px;
        padding: 10px 15px;
        margin: 1rem 0;
    }

    .updateStatus-update-btn {
        margin: 0.5rem 0;
    }

    .updateStatus-update-btn-cancel {
        margin: 0.5rem 0;
    }

    .updateStatus-container-header h3 {
        font-size: 18px;
    }
}