.settings {
    margin-top: 70px;
    padding-bottom: 50px;
}

.settings-header {
    padding: 1rem;
    background-color: #eee;
}

.settings-header h3 {
    font-size: 25px;
}

.settings-price-adjustment {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 1rem;
}

.settings-price-adjustment p {
    font-size: 15px;
}

.settings-price-adjustment span {
    font-size: 18px;
    font-weight: bold;
}

.price-adjustment-div {
    margin: 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.price-adjustment-div input {
    width: 300px;
    background: #eee;
    border-radius: 8px;
    border: 3px solid #2858A5;
    font-size: 14px;
    padding: 4px 8px;
    margin: 8px 2px;
}

.price-adjustment-btn {
    font-size: 14px;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border: none;
    font-weight: bold;
    background-color: #ff9401;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.price-adjustment-btn:hover {
    transform: translateY(-3px);
}


/* ---------------------------delete user---------------------------- */

.settings-delete-user {
    margin: 2rem 0;
}

.settings-delete-user-header {
    padding: 1rem;
    background-color: #eee;
}

.settings-delete-user-header h3 {
    font-size: 25px;
}

/* ----------------------------view staff---------- */

.display-staff-members-table {
    margin: 1rem;
}

.display-staff-members-heading {
    padding: 1rem;
    background-color: #eee;
}

.display-staff-members-heading h3 {
    font-size: 25px;
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    background: #fff;
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background: #fff;
}


/* ------------------------------------price-------------------------  */

.price-div {
    margin: 2rem;
    display: flex;
    align-items: center;
}

.price-table {
    width: 300px;
    box-shadow: -4px 8px 15px -8px rgba(0, 0, 0, 0.5);
    border-collapse: collapse;
    cursor: pointer;
}

.price-table-row {
    background-color: #eee;
}


.price-table tbody tr:nth-child(odd) {
    background-color: rgb(185, 214, 241);
}

.price-table-row td {
    text-align: left;
    padding: 0.3rem 0.7rem;
    font-size: 14px;
    border: none !important;
}

.price-table-td {
    text-align: right;
}