.sidebar {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: var(--yokan-blue-light-color);
    padding-left: 20px;
    padding-right: 5px;
    /* overflow-y: scroll; */
}

.sidebar__top {
    width: 100%;
    height: 70px;
    line-height: 70px;
}

.sidebar__top__logo__div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    margin: 5px;
    overflow: hidden;
}

.sidebar__top__logo__div img {
    height: 60px;
}

.sidebar__top__heading {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--heading-color);
}

.sidebar__top h2 span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--card-04-bg);
    padding: 1.3rem;
    border-radius: 50%;
}

.sidebar__top h2 i {
    font-weight: 400 !important;
    padding: 5px;
    font-size: 1.5rem;
}

.sidebar__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 5%);
    margin-top: 20px;
}

.menu {
    height: 80%;
    overflow-y: scroll;
}

.sidebar__bottom {
    height: 20%;
}

.nav__link,
.sidebar__bottom span {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
}

.nav__list {
    display: flex;
    row-gap: 2rem;
    flex-direction: column;
    color: white;
}

.sidebar__bottom span {
    color: white;
    cursor: pointer;
    padding-top: 1.5rem;
}

.nav__link i {
    color: var(--secondary-color);
}

.nav__active {
    color: var(--yokan-yellow-primary-color);
    background: #b7ffe913;
    padding: 6px;
    border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 900px) {}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .sidebar {
        width: 240px;
    }

    .nav__link,
    .sidebar__bottom span {
        font-size: 14px;
    }
}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .sidebar {
        width: 220px;
    }

    .nav__link,
    .sidebar__bottom span {
        font-size: 14px;
    }

    .nav__list {
        row-gap: 1.5rem;
    }
}


@media only screen and (max-width: 485px) {
    .sidebar {
        width: 200px;
    }

    .nav__link,
    .sidebar__bottom span {
        font-size: 12px;
    }

    .nav__list {
        row-gap: 1rem;
    }
}