.top__nav {
    position: fixed;
    top: 0;
    left: 260px;
    z-index: 999;
    background: var(--yokan-blue-light-color);
    width: calc(100% - 260px);
    height: 70px;
}

.top__nav__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 3rem;
    gap: 10px;
    height: 70px;
}

.top__nav__wrapper__column {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
}

.top__nav__wrapper__text {
    display: flex;
    gap: 10px;
}

.top__nav__wrapper h2 {
    font-size: 14px;
    color: var(--light-white);
}

@media only screen and (min-width: 768px) and (max-width: 900px) {}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .top__nav {
        left: 240px;
        width: calc(100% - 240px);
        height: 70px;
    }

    .top__nav__wrapper {
        padding: 0 2rem;
        gap: 10px;
        height: 70px;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .top__nav {
        left: 220px;
        width: calc(100% - 220px);
        height: 70px;
    }

    .top__nav__wrapper {
        padding: 0 2rem;
        gap: 10px;
        height: 70px;
    }

    .top__nav__wrapper h2 {
        font-size: 12px;
    }
}


@media only screen and (max-width: 485px) {
    .top__nav {
        left: 200px;
        width: calc(100% - 200px);
        height: 70px;
    }

    .top__nav__wrapper {
        padding: 0 2rem;
        gap: 10px;
        height: 70px;
    }

    .top__nav__wrapper h2 {
        font-size: 9px;
    }
}