.createShipment {
    margin-top: 100px;
    padding: 1rem;
    padding-bottom: 50px;
}

.createShipment-container-header h3 {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}

.row-form-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row-form-div-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-div-input .row-form-div .input-label {
    width: 48%;
}

.form-div-input .row-form-div-sub .input-label {
    width: 48%;
}

.form-div-input h2 {
    padding: 1rem;
}

.flex-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-inputs .input-label {
    width: 32%;
}

.item-div-input-row {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.item-div-input-row p {
    padding: 0 1rem;
}

.phone-email-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phone-email-input .input-label {
    width: 48%;
}

.item-div-input-row-three {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item-div-input-row-three .input-label {
    width: 32%;
}

.input-label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0.5rem 0;
}

.input-label select,
.input-label input {
    padding: 10px 15px;
    margin: 8px 0;
    background: #eee;
    border: none;
}

/* -------------------ship item-------------------- */


.shipment-detail-date {
    display: flex;
    padding: 1rem 1rem 1rem 0;
}

.shipment-detail-date p {
    padding-right: 1rem;
}

.shipment-detail-date-picker {
    display: flex;
    align-items: center;
}

.shipment-detail-datepicker {
    width: 170px;
    height: 32px;
    padding: 5px;
    font-size: 15px;
}

.shipment-detail-date-picker i {
    padding-left: 1rem;
    font-size: 28px;
    color: rgb(0, 81, 128);
}

.shipment-detail-discription {
    margin: 0 1rem;
}

.shipment-detail-heading-discription {
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input-error {
    border-color: red;
}

.error-text {
    color: red;
}

.price-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 5rem;
}

.price-display-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.price-display-div h4 {
    padding: 0 5px;
}


/* ------------------form-btn---------------------- */

.form-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.continue-shipment {
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    border: none;
    background-color: #ff9401;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: 0.4s ease;
}

.continue-shipment:hover {
    transform: translateY(-3px)
}

.cancel-Shipment {
    padding: 1rem 3rem;
    text-decoration: underline;
    text-align: center;
    color: blue;
    cursor: pointer;
}

/* ----------------------------Notification Component---------------- */

.notificationContainer {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
}

.notificationBox {
    height: 60px;
    background-color: rgb(255, 204, 35);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-radius: 1rem;
}

.notificationBox p {
    font-size: 20px;
    color: #fff;
    color: red;
    font-weight: 500;
}

.notificationBox i {
    background-color: white;
    color: red;
    font-size: 2rem;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
}

.message {
    margin: 0;
}

.loading-spin {
    width: 100%;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.loading-spin {
    background: #fff;
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .phone-email-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .createShipment-container-header h3 {
        font-size: 36px;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .flex-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .phone-email-input .input-label {
        width: 100%;
    }

    .flex-inputs .input-label {
        width: 100%;
    }

    .shipment-detail-date {
        display: flex;
        flex-direction: column;
    }

}


@media only screen and (min-width: 485px) and (max-width: 600px) {
    .createShipment-container-header h3 {
        font-size: 26px;
    }

    .form-div-input h2 {
        font-size: 18px;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .flex-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .phone-email-input .input-label {
        width: 100%;
    }

    .form-div-input .row-form-div .input-label {
        width: 100%;
    }

    .flex-inputs .input-label {
        width: 100%;
    }

    .row-form-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .shipment-detail-date {
        display: flex;
        flex-direction: column;
    }

    .item-div-input-row-three {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .item-div-input-row-three .input-label {
        width: 100%;
    }
}

@media only screen and (max-width: 485px) {
    .createShipment-container-header h3 {
        font-size: 22px;
    }

    .form-div-input h2 {
        font-size: 16px;
    }

    .phone-email-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .flex-inputs {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .phone-email-input .input-label {
        width: 100%;
    }

    .form-div-input .row-form-div .input-label {
        width: 100%;
    }

    .flex-inputs .input-label {
        width: 100%;
    }

    .row-form-div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .shipment-detail-date {
        display: flex;
        flex-direction: column;
    }

    .item-div-input-row-three {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    .item-div-input-row-three .input-label {
        width: 100%;
    }
}